<template>
  <div class="Appdownload">
    <div class="bigImg">
      <p>世界一手掌握</p>
      <span>一机在手，指尖盈利</span>
      <div>
        <img @click='iosDownload' src="../../static/appDownload/wap/ios.png" alt="">
        <img @click='androidDownload' src="../../static/appDownload/wap/android.png" alt="">
      </div>
    </div>
    <div class="iconBox">
      <div class="ibsBox">
        <div>
          <img src="../../static/appDownload/zx.png" alt="">
          <span>丰富的行情资讯</span>
        </div>
        <div>
          <img src="../../static/appDownload/bj.png" alt="">
          <span>精准的产品报价</span>
        </div>
        <div>
          <img src="../../static/appDownload/jl.png" alt="">
          <span>精彩的广场互动</span>
        </div>
        <div>
          <img src="../../static/appDownload/zys.png" alt="">
          <span>智能止盈止损</span>
        </div>
        <div>
          <img src="../../static/appDownload/hd.png" alt="">
          <span>丰富的新手活动</span>
        </div>
        <div>
          <img src="../../static/appDownload/zc.png" alt="">
          <span>点差低，免费注册</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getChannelInfo, getConfigurationValue } from '../../api/info';
  export default {
    name: "Appdownload",
    metaInfo: {  
      title: '红狮金业APP,现货黄金白银投资专业软件,伦敦金伦敦银靠谱平台,黄金交易红狮启富APP',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易软件,贵金属投资APP,贵金属平台,现货黄金开户,现货白银,正规靠谱平台。' },  
        { name: 'description', content: '红狮金业APP是一款稳定安全的交易软件，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },  
    data(){
      return{
        androidUrl: 'https://oss.0790jiaxiao.com/hsnew/apk/2.9.2/red_lionRelease-V2.9.2_9999.apk',
        iosUrl: 'https://apps.apple.com/us/app/id6451142175',
      }
    },
    created(){
      this.getAndroidUrl('9999');
      this.getIosUrl();
      setTimeout(()=>{
        document.getElementsByClassName("navBarWrap")[0].style.height = '';
      },50)
    },
    mounted(){

    },
    methods:{
      //获取Android下载链接
      async getAndroidUrl(code) {
        const res = await getChannelInfo(code)
        const { downloadUrl } = res.Data;
        this.APKurl = downloadUrl || '';
        const { origin } = window.location;
        const absoluteURLRegex = /^[a-zA-Z]+:\/\/.+/;
        if (absoluteURLRegex.test(downloadUrl)) {
          this.androidUrl = downloadUrl
        } else {
          this.androidUrl = origin + '/' + downloadUrl
        }
      },
      //获取ios下载链接
      async getIosUrl() {
        const res = await getConfigurationValue({key: 'IOSDownLoadLinkMT5'})
        console.log(res, 11111)
        if(res.Data) {
          this.iosUrl = res.Data
        }
      },
      iosDownload(){
        // window.location.href = 'https://apps.apple.com/cn/app/id1598072476';
        window.location.href = this.iosUrl;
      },
      androidDownload(){
        window.location.href = this.androidUrl;
      }
    }
  }
</script>

<style lang="less" scoped>
  .Appdownload{
    width: 7.50rem;
    margin: 0 auto;
    text-align: center;
    .bigImg{
      width: 100%;
      height: 9.23rem;
      background: url('../../static/appDownload/wap/banner.jpg') no-repeat;
      background-size: cover;
      box-sizing: border-box;
      padding-top: 1rem;
      p{
        margin: 0;
        font-size: 0.64rem;
        color: #ffffff;
      }
      span{
        display: block;
        font-size: 0.36rem;
        color: #fff;
        margin: 0.4rem auto 0.7rem;
      }
      div{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 2.16rem;
          height: auto;
        }
        img:nth-child(1){
          margin-right: 0.18rem;
        }
      }
    }
    .iconBox{
      width: 100%;
      height: 9.28rem;
      box-sizing: border-box;
      background: url('../../static/appDownload/wap/gongneng.png') no-repeat;
      background-size: cover;
      padding: 0.8rem 0;
      .ibsBox{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        div{
          width: 48%;
          height: 2.45rem;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 0.15rem;
          img{
            width: 0.9rem;
            height: auto;
            margin-bottom: 0.3rem;
          }
          span{
            font-size: 0.22rem;
            font-weight: 600;
            margin-bottom: 0.35rem;
          }
        }
      }
    }
  }
</style>